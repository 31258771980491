@import "./../../styles/colors";

$height: 28px;
$width: 120px;
$halfWidth: calc($width / 2);

.container {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    align-items: center;

    .checkbox {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            & + .slider {
                &:before {
                    left: $halfWidth;
                }

                .labelChecked {
                    color: $whiteColor;
                }

                .labelUnchecked {
                    color: $separatorColor;
                }
            }
        }
    }

    * + .checkbox {
        margin-left: 20px;
    }

    .slider {
        display: inline-block;
        position: relative;
        width: $width;
        height: $height;
        border-radius: $height;
        background-color: $greyColor;
        flex-shrink: 0;

        &:before {
            position: absolute;
            content: "";
            height: $height;
            width: $halfWidth;
            left: 0;
            bottom: 0;
            background-color: $mainColor;
            transition: 0.2s all ease;
            border-radius: $height;
        }

        .labelChecked,
        .labelUnchecked {
            display: inline-block;
            line-height: 28px;
            width: $halfWidth;
            text-align: center;
            position: absolute;
            top: 0;
            bottom: 0;
            transition: all 0.2s ease;
            user-select: none;
        }

        .labelChecked {
            color: $separatorColor;
            left: $halfWidth;
        }

        .labelUnchecked {
            color: $whiteColor;
            left: 0;
        }
    }
}
