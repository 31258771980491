@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    @include open-sans-22-regular;
    color: $mainColor;

    &.normalSize {
        @include open-sans-22-regular;
    }

    &.smallSize {
        @include open-sans-14-regular;
    }
}
