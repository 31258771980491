@import "./../../styles/colors";

.container {
    max-width: 800px;

    .row {
        & + .row {
            margin-top: 24px;
        }
    }
}
