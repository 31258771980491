@import "./../../styles/colors";
@import "./../../styles/mixins";

.container {
    @include board-action-header;

    .canvas {
        height: 100%;
        width: 100%;
        overflow: hidden;
        flex: 1;
    }
}

.storybook {
    height: 840px;
    width: 1500px;
}
