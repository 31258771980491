@import "./../../styles/colors";

.container {
    width: 100%;
    border: 1px solid $mainColor;
    padding: 24px;
    border-radius: 7px;

    .textInput,
    .uriInputContainer {
        width: 100%;
    }

    .uriInputContainer {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .uriInput {
            flex: 1;
        }

        .logo {
            flex-shrink: 0;
            margin-left: 4px;
            margin-bottom: 2px;
            max-width: 80px;
        }
    }

    .button {
        margin-top: 24px;
    }
}
