@import "./../../styles/colors";
@import "./../../styles/text";
@import "./../../styles/dimensions";

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .input {
        flex: 1;
        min-height: $barHeight;

        @include open-sans-16-regular;
        color: $mainColor;
        cursor: text;
        border-radius: 7px;
        background-color: $greyColor;
        border: none;
        padding: 11px 16px;
        transition: all 0.2s ease-out;
        resize: none;

        &::placeholder {
            opacity: 0.5;
        }
    }

    .button {
        flex-shrink: 0;
        margin-left: 4px;
    }
}
