@import "./../../styles/colors";
@import "./../../styles/animations";

.container {
    background: $whiteColor;
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid $separatorColor;
    animation: fadeIn 0.5s ease-in;
}
