@import "./../../styles/colors";
@import "./../../styles/animations";

.container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: $greyColor;
    align-items: stretch;
    overflow: hidden;

    .navbar,
    .footer {
        flex-shrink: 0;
        width: 100%;
    }

    .navbar {
        animation: fadeIn 0.4s ease-out;
    }

    .footer {
        animation: fadeIn 0.6s ease-in;
    }

    .content {
        flex: 1;
        overflow: hidden;
    }
}
