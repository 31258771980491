@import "./../../styles/colors";

.container {
    position: relative;
    display: inline-flex;

    .image {
        cursor: pointer;
        transition: all 0.2s ease;
        height: 30px;
        width: 78px;

        &:hover {
            opacity: 0.7;
        }
        &:active {
            opacity: 0.4;
        }
    }

    .arrow {
        position: absolute;
        pointer-events: none;
        top: 12px;
        right: 3px;
        width: 11px;
        transition: all 0.2s ease;
        transform: rotate(-180deg);
    }

    .tooltip {
        min-width: 200px;
        pointer-events: none;
        transition: all 0.2s ease;
        opacity: 0;
        position: absolute;
        left: -2px;
        top: calc(100% + 12px);
        background: $dropdownOptionsColor;
        border: 1px solid $separatorColor;
        box-shadow: 2px 2px 12px #00000029;
        padding: 13px;
        border-radius: 7px;
        white-space: nowrap;

        $cornerSize: 12px;

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 0;
            left: calc(19px - $cornerSize);
            bottom: calc(100%);
            border: ($cornerSize) solid transparent;
            border-bottom-color: $dropdownOptionsColor;
        }
    }

    .transaction {
        margin-top: 5px;
    }

    .row {
        + .row {
            margin-top: 5px;
        }
    }

    .inline {
        display: inline-block;
    }

    .link {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }

    &.open {
        .arrow {
            transform: rotate(0);
        }

        .tooltip {
            pointer-events: all;
            opacity: 1;
        }
    }
}

.storybook {
    margin-left: 100px;
}
