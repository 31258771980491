@import "./../../styles/colors";
@import "./../../styles/text";
@import "./../../styles/dimensions";

.container {
    height: $bigMemoSize;
    width: $bigMemoSize;
    padding: 2px;
    background-repeat: no-repeat;
    background-size: $bigMemoSize $bigMemoSize;
    filter: drop-shadow(6px 7px 7px rgba(0, 0, 0, 0.37));
    cursor: text;

    .input {
        resize: none;
        border: none;
    }

    .textContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
            overflow: hidden;
            word-break: break-word;
            width: 100%;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            max-height: 100%;
            text-align: center;
            white-space: pre-line;
        }
    }
    .textContainer,
    .input {
        @include patrick-hand-39-regular;
        background: none;
        width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
        color: $mainColor;
    }
}
