@import "./../../styles/colors";

.container {
    display: inline-flex;
    align-items: center;

    .icon {
        margin-right: 8px;
    }

    .title {
        &.computations {
            color: $orangeColor;
        }
        &.tables {
            color: $aubergineColor;
        }
        &.interfaces {
            color: $redColor;
        }
        &.validations {
            color: $greenColor;
        }
        &.mutations {
            color: $purpleColor;
        }
        &.processes {
            color: $blueColor;
        }
    }
}
