@import "./../../styles/colors";
@import "./../../styles/dimensions";

@mixin slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $secondaryAccentColor;
    cursor: pointer;
    border: 2px solid $whiteColor;
    box-shadow: 0px 0px 6px #0000007f;
}

$spacing: 20px;

.container {
    display: grid;
    grid-template-columns: 200px 1fr 1fr 1fr;
    grid-template-rows: $barHeight auto;
    background: $separatorColor;
    grid-gap: 1px;

    .header {
        background: $whiteColor;
        display: flex;
        align-items: center;

        .title {
            flex: 1;
            margin-left: $spacing;
        }

        .icon {
            flex-shrink: 0;
            border-left: 1px solid $separatorColor;
        }
    }

    .body {
        background: $whiteColor;
        padding: $spacing;

        .row {
            margin-top: $spacing;
            height: $barHeight;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            &:first-child {
                margin-top: calc(2 * $spacing);
            }

            &.value {
                background: $greyColor;
                border-radius: 7px;
                text-align: center;
                justify-content: center;
                margin-left: $spacing;
                margin-right: $spacing;
            }

            .text {
                line-height: $barHeight;
            }

            .avatar {
                margin-right: 13px;
            }

            .positive {
                color: $greenColor;
            }

            .negative {
                color: $redColor;
            }

            .neutral {
                color: $mainColor;
            }

            // Styling from https://www.w3schools.com/howto/howto_js_rangeslider.asp
            .slider {
                -webkit-appearance: none;
                width: 100%;
                height: 1px;
                border-radius: 5px;
                background: $mainColor;
                outline: none;
                opacity: 0.7;
                -webkit-transition: 0.2s;
                transition: opacity 0.2s;
                margin-left: $spacing;
                margin-right: $spacing;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    @include slider-thumb;
                }

                &::-moz-range-thumb {
                    @include slider-thumb;
                }
            }

            .tickContainer {
                display: flex;
                flex-direction: row;
                position: absolute;
                justify-content: space-between;
                left: $spacing;
                right: $spacing;
                bottom: 10px;

                .tick {
                    background: $mainColor;
                    height: 20px;
                    width: 1px;
                }

                $emoteSize: 30px;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    bottom: calc(100% + 8px);
                    height: $emoteSize;
                    width: $emoteSize;
                }

                &:before {
                    left: calc($emoteSize / -2);
                    background: url("./../../assets/images/Image\ 2.png");
                }

                &:after {
                    right: calc($emoteSize / -2);
                    background: url("./../../assets/images/Image\ 3.png");
                }
            }
        }
    }
}

.storybook {
    width: 1150px;
}
