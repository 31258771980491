@import "./../../styles/colors";
@import "./../../styles/animations";

.container {
    display: flex;
    height: 100%;

    .cover {
        flex: 1;
        min-width: 400px;
        height: 100%;
        overflow: hidden;
        background-color: $mainColor;
        position: relative;
        animation: slideInLeft 1s ease-in-out, fadeIn 1.2s ease-out;

        .img,
        .front {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .img {
            background-image: url(./../../assets/images/brooke-cagle--uHVRvDr7pg-unsplash.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            opacity: 0.6;
        }

        .front {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }

    .loginContainer {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 900px;
        min-width: 600px;
        align-items: center;

        animation: fadeIn 0.8s ease-in-out;

        .form {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            min-width: 400px;
            width: 70%;
            max-width: 500px;
            justify-content: center;

            .title {
                text-align: center;
                margin-bottom: 48px;
            }

            .textInput {
                &.textInput {
                    margin-top: 36px;
                }
            }

            .options {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 16px;
            }

            .button {
                margin-top: 32px;
            }
        }

        .footer {
            flex-shrink: 0;
        }
    }
}
