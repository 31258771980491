@import "./colors";
@import "./text";
@import "./animations";

@mixin modal-header-content {
    .headerItem {
        flex-shrink: 0;
    }

    .filler {
        flex: 1;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 16px;
    }
}

@mixin modal-overlay {
    .overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($mainColor, 0.47);
        z-index: 1000;
        backdrop-filter: blur(8px);
    }

    .container {
        animation: grow 0.3s ease, fadeIn 0.4s ease;
    }
}

@mixin board-action-header {
    .name {
        margin: 0 3px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .dropdown {
        margin: 0 13px 0 12px;
    }

    .toggleSwitch {
        margin: 0 15px;

        .myMemoIcon {
            width: 20px;
        }
    }

    .filler {
        flex: 1;
    }

    .breadcrumb {
        margin-left: 8px;
    }
}
