@import "./../../styles/colors";
@import "./../../styles/text";

.input {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
    @include patrick-hand-18-regular;
    color: $mainColor;
    text-align: center;
    vertical-align: middle;
    line-height: 18px;
}
