@import "./../../styles/colors";

.container {
    max-width: 1400px;

    .add {
        width: 100%;
        max-width: 800px;
    }

    .filterBar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name {
            flex-shrink: 0;
            margin-right: 8px;
        }

        .dropdown {
            max-width: 400px;
            flex: 1;
        }
    }

    .row {
        & + .row {
            margin-top: 24px;
        }
    }
}
