@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    display: grid;
    grid-template-columns: 1fr $barHeight;
    grid-template-rows: $barHeight 1fr;
    grid-template-areas:
        "action1 help"
        "content action2";
    grid-gap: 1px;
    background: $separatorColor;

    &.hideSecondary {
        grid-template-areas:
            "action1 help"
            "content content";
    }

    .gridBlock {
        background: $whiteColor;
        overflow: hidden;
        height: 100%;
    }

    .mainAction {
        grid-area: action1;

        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: initial;
        z-index: 10;
    }

    .help {
        grid-area: help;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        grid-area: content;
    }

    .secondaryAction {
        grid-area: action2;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.storybook {
    height: 100%;
    width: 100%;
}
