@import "./../../styles/colors";

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .textInput {
        flex: 1;

        + .button {
            margin-left: 24px;
        }
    }

    .button {
        flex-shrink: 0;

        & + .button {
            margin-left: 12px;
        }
    }
}
