@import "./../../styles/colors";
@import "./../../styles/animations";

.container {
    background: $whiteColor;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 2px 6px #0000000f;
    animation: fadeIn 0.5s ease-in;
}
