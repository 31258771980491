@import "./../../styles/colors";

.container {
    height: 36px;
    max-height: 36px;
    max-width: 80%;
    width: 80%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: transparent;

    &.large {
        height: 80px;
        max-height: 80px;
    }
}
