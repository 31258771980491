@import "./../../styles/colors";
@import "./../../styles/text";

.text {
    margin-bottom: 10px;
}

.input {
    @include open-sans-16-regular;
    width: 100%;
    color: $mainColor;
    cursor: text;
    border-radius: 7px;
    background-color: $greyColor;
    border: none;
    padding: 14px 19px;
    transition: all 0.2s ease-out;

    &::placeholder {
        opacity: 0.5;
    }

    &:disabled {
        cursor: not-allowed;
    }
}
