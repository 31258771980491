@import "./../../styles/colors";

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .textInput {
        flex: 3;

        + .uriInputContainer {
            margin-left: 12px;
        }
    }

    .uriInputContainer {
        flex: 2;
        display: flex;
        flex-direction: row;
        align-items: center;

        + .button {
            margin-left: 24px;
        }

        .uriInput {
            flex: 1;
        }

        .logo {
            flex-shrink: 0;
            max-width: 60px;
            margin-left: 4px;
        }
    }

    .button {
        flex-shrink: 0;

        & + .button {
            margin-left: 12px;
        }
    }
}
