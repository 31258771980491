@import "./styles/colors";

.app {
    height: 100%;
    background-color: $whiteColor;
    color: $mainColor;
    overflow: auto;

    .start {
        height: 100%;
    }
}
