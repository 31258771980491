@import "./../../styles/colors";
@import "./../../styles/text";
@import "./../../styles/dimensions";
@import "./../../styles/mixins";

.container {
    @include board-action-header;

    .inheritanceDropdown {
        width: 200px;
    }

    .board {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas:
            "tables tables tables tables rules rules"
            "interfaces interfaces validations validations rules rules"
            "mutations mutations mutations processes processes processes";
        grid-gap: 2px;
        background-color: $separatorColor;
        height: 100%;
        width: 100%;

        .tables {
            grid-area: tables;
        }
        .computations {
            grid-area: rules;
        }
        .interfaces {
            grid-area: interfaces;
        }
        .validations {
            grid-area: validations;
        }
        .mutations {
            grid-area: mutations;
        }
        .processes {
            grid-area: processes;
        }

        .block {
            transition: all 0.2s ease;
            background-color: $whiteColor;
            max-height: 100%;
            max-width: 100%;
            overflow: hidden;
            position: relative;

            .canvas {
                height: 100%;
                width: 100%;
                overflow: hidden;
            }

            .canvasTitle {
                position: absolute;
                left: 12px;
                top: 12px;
                pointer-events: none;
            }

            .expand {
                position: absolute;
                right: 4px;
                top: 4px;
            }
        }
    }

    .fullscreen {
        flex: 1;
        height: 100%;
        overflow: hidden;
        background-size: 12px 12px;
        background-image: radial-gradient(circle, $separatorColor 1px, $greyColor 1px);

        display: grid;
        grid-template-rows: $barHeight auto;

        .subbar {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: $barHeight;
            border-bottom: 1px solid $separatorColor;

            .canvasTitle {
                flex: 1;
                margin-left: 12px;

                & ~ * {
                    flex-shrink: 0;
                }
            }
        }

        .canvas {
            height: 100%;
            width: 100%;

            max-height: $maxMemoCanvasHeight;
            max-width: $maxMemoCanvasWidth;

            overflow: auto;
            border-radius: 7px;
            align-self: center;
            justify-self: center;
        }

        .canvas,
        .subbar {
            background: $whiteColor;
        }
    }
}

.storybook {
    height: 840px;
    width: 1500px;
}
