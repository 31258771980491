@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid $separatorColor;
        height: $barHeight;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        .title {
            padding: 0 25px;
        }

        .filler {
            flex: 1;
        }

        .headerItem {
            flex-shrink: 0;
        }
    }

    .messages {
        padding: 7px 17px 0;
        overflow: auto;
        width: 100%;
        flex: 1;

        .message + .message {
            margin-top: 12px;
        }
    }

    .input {
        width: 100%;
        padding: 17px;
    }

    // Useful for stories where i want to test the scroll
    &.limitHeight {
        height: 600px;
    }
}
