$memoSize: var(--memo-size);

// These values have been discussed in team,
// this is to prevent large screens from hiding elements at the larger areas,
// invisible for smaller screens
$maxMemoCanvasHeight: var(--memo-canvas-max-height);
$maxMemoCanvasWidth: var(--memo-canvas-max-width);

$maxBlueprintCanvasHeight: var(--blueprint-canvas-max-height);
$maxBlueprintCanvasWidth: var(--blueprint-canvas-max-width);

$reduction: 0.8;
$barHeight: $reduction * 50px;
$iconSize: $barHeight;

$sidebarWidth: 260px;
$spaceBetweenCards: 12px;

$bigMemoSize: 160px;
