@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    padding: $spaceBetweenCards;
    display: flex;
    height: 100%;
    flex-direction: row;

    .sidebar {
        width: $sidebarWidth;
        margin-right: $spaceBetweenCards;
        flex-shrink: 0;
    }

    .main {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .board {
            flex: 1;
            width: 100%;
        }
        .timeline {
            margin-top: $spaceBetweenCards;
            flex-shrink: 0;
        }
    }
}
