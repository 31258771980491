@import "./../../styles/colors";

.container {
    position: relative;
    width: 160px;
    display: flex;
    align-items: center;

    .select {
        width: 100%;
        display: inline-flex;
        align-items: center;
        height: 30px;
        border: none;
        border-radius: 7px;
        transition: all 0.2s ease;
        background: $greyColor;
        overflow: hidden;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }

        &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .arrow {
                transform: rotate(0deg);
            }
        }

        .icon {
            flex-shrink: 0;
            width: 30px;
            margin-left: 10px;
        }

        .label {
            flex: 1;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 8px;
        }

        .arrow {
            flex-shrink: 0;
            transition: all 0.2s ease;
            width: 32px;
            transform: rotate(-180deg);
        }
    }

    .optionsContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        overflow: hidden;
        list-style: none;
        z-index: 20;

        .option {
            background: $dropdownOptionsColor;
            height: 32px;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            position: relative;

            &.selected {
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 5px;
                    background: $secondaryAccentColor;
                }
                .label {
                    font-weight: bold;
                }
            }

            .icon {
                flex-shrink: 0;
                width: 30px;
                margin-left: 10px;
            }

            .label {
                transition: all 0.2s ease;
                line-height: 32px;
                display: inline-block;
                flex: 1;
                margin-left: 8px;
            }

            + .option {
                border-top: 1px solid $whiteColor;
            }

            &:hover {
                background: $secondaryAccentColor;
                .label {
                    color: $whiteColor;
                }
            }
        }
    }
}
