@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    height: $barHeight;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $whiteColor;

    .line {
        background-image: url("./../../assets/images/sixblocks-line.svg");
        height: 4px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        flex-shrink: 0;
    }
    .content {
        display: grid;
        grid-template-columns: 320px auto 320px;
        width: 100%;
        grid-gap: 24px;
        justify-items: center;
        align-items: center;
        flex: 1;
        overflow: hidden;

        .poweredBy {
            margin-left: 28px;
            place-self: center start;
        }

        .quote {
            text-align: center;
        }

        .copyright {
            margin-right: 14px;
            place-self: center end;
        }
    }
}
