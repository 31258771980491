@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    @include open-sans-16-regular;
    color: $mainColor;
    transition: all 0.2s ease-out;

    &.body {
        @include open-sans-16-regular;
    }

    &.tiny {
        @include open-sans-14-regular;
    }

    &.bold {
        @include open-sans-16-bold;
    }

    &.tooltip {
        @include open-sans-16-semibold;
    }

    &.large {
        @include open-sans-28-semibold;
    }
}
