@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    height: 100%;
    width: 100%;

    .tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $mainColor;
        border-radius: 7px;
        padding: 8px 17px;
        position: relative;

        .tooltipText {
            color: $whiteColor;
        }

        $cornerSize: 10px;

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 0;
            left: calc(50% - $cornerSize);
            top: calc(100% - 1px);
            border: ($cornerSize) solid transparent;
            border-top-color: $mainColor;
        }
    }

    .point {
        cursor: pointer;
    }

    .today {
        pointer-events: none;
        transform: scale(0.9);
    }

    .shape {
        fill: $whiteColor;
        stroke: $mainColor;
        transition: all 0.2s ease;

        &.selected {
            fill: $mainColor;

            &.inProgress {
                fill: $orangeColor;
            }
        }

        &.inProgress {
            stroke: $orangeColor;
            stroke-width: 2px;
        }

        &:hover {
            stroke: $purpleColor;
            fill: $purpleColor;
        }

        &:active {
            fill: $mainColor;
        }
    }

    .todayLabel {
        @include open-sans-16-semibold;
        fill: $mainColor;
    }

    .tickLabel {
        @include open-sans-16-regular;
        fill: $mainColor;
    }
}
