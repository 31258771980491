@import "./../../styles/colors";
@import "./../../styles/mixins";
@import "./../../styles/dimensions";

.container {
    max-width: 1200px;
    min-width: 600px;
    width: 80%;
    background: $whiteColor;
    min-height: 680px;
    max-height: 90%;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 0px 20px #00000029;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .header {
        @include modal-header-content;
    }

    .contentContainer {
        display: grid;
        grid-template-columns: $bigMemoSize auto;
        grid-template-rows: $bigMemoSize auto;
        grid-template-areas:
            "memo description"
            "content content";
        grid-gap: 20px;
        padding: 16px;
        overflow: hidden;
        flex: 1;

        .memo {
            grid-area: memo;
        }

        .description {
            grid-area: description;
        }

        .content {
            grid-area: content;
        }
    }
}

@include modal-overlay;
