@import "./../../styles/colors";
@import "./../../styles/text";

.wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.text {
    @include open-sans-16-regular;
    color: $mainColor;
}
