@import "./../../styles/colors";

.container {
    display: block;
    background: $separatorColor;

    &.horizontal {
        height: 1px;
        width: 100%;
    }

    &.vertical {
        height: 100%;
        width: 1px;
    }
}
