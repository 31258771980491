@import "./../../styles/colors";

.container {
    .label {
        cursor: pointer;
        line-height: 30px;
        .text {
            display: inline-block;
            position: relative;

            &:before {
                content: "";
                background-image: url("./../../assets/images/unchecked.svg");
                border: none;
                display: inline-block;
                vertical-align: middle;
                width: 30px;
                height: 30px;
                padding: 2px;
                margin-right: 11px;
            }
        }

        .input {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            &:checked + .text {
                &:before {
                    background-image: url("./../../assets/images/checked.svg");
                }
            }
        }
    }
}
