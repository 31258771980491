@import "./../../styles/colors";
@import "./../../styles/mixins";

.container {
    max-width: 800px;
    min-width: 600px;
    width: 100%;
    background: $whiteColor;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 0px 20px #00000029;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .header {
        @include modal-header-content;
    }

    .contentContainer {
        border-radius: 7px;
        padding: 20px;
        margin: 20px;
        border: 1px solid $separatorColor;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            flex: 1;

            .column {
                width: 100%;

                & + .column {
                    margin-left: 40px;
                }

                .textInput {
                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
                .text {
                    margin-bottom: 10px;
                }
            }
        }
        .button {
            margin-top: 40px;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

@include modal-overlay;
