@import "./../../styles/colors";

.container {
    width: 100%;
    border: 1px solid $mainColor;
    padding: 24px;
    border-radius: 7px;

    .input {
        width: 100%;

        + .input {
            margin-top: 12px;
        }

        .label {
            margin-bottom: 10px;
        }
    }

    .avatarContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .avatarInput {
            flex: 1;
        }

        .avatar {
            flex-shrink: 0;
            margin-left: 4px;
            margin-bottom: 2px;
        }
    }

    .passwordContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .passwordInput {
            flex: 1;
        }

        .passwordGenerator {
            flex-shrink: 0;
        }
    }

    .dropdown {
        width: 100%;
    }

    .button {
        margin-top: 24px;
    }
}
