@import "./../../styles/colors";
@import "./../../styles/text";
@import "./../../styles/dimensions";

$padding: 16px;

.container {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid $separatorColor;
        height: $barHeight;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding-left: $padding;

        .title {
            flex: 1;
        }

        .headerItem {
            flex-shrink: 0;
        }
    }

    .input {
        @include open-sans-16-regular;
        resize: none;
        border: none;
        flex: 1;
        width: 100%;
        color: $mainColor;
        padding: $padding;

        &:disabled {
            background-color: initial;
        }
    }
}
