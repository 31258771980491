@import "./../../styles/colors";
@import "./../../styles/text";

$rowHeight: 36px;
$margin: 12px;
$outerMargin: 10px;

.container {
    margin: $outerMargin;
    .line {
        margin: $outerMargin 0;
        border-top: 1px solid $separatorColor;
        height: 0px;
    }

    .context {
        display: flex;
        align-items: center;
        margin: 4px 0;

        .contextName {
            flex: 1;
            margin-left: $margin;
        }

        .icon {
            flex-shrink: 0;
            transition: all 0.2s ease;
            transform: rotate(-180deg);

            &.selected {
                transform: rotate(0);
            }
        }
    }

    .subContextLink {
        display: block;

        & + .subContextLink {
            margin-top: 4px;
        }

        .subContextName {
            padding: 0 $margin;
            line-height: $rowHeight;
            margin-left: $margin;
        }
    }

    .contextName,
    .subContextName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .context,
    .subContextName {
        height: $rowHeight;
        border-radius: 7px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background: $greyColor;
        }

        &.selected {
            background: $selectedContextColor;
        }

        &:active {
            background: $separatorColor;
        }
    }
}
