@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    display: flex;
    align-items: center;

    .text {
        @include open-sans-26-regular;
        color: $whiteColor;
        padding-right: 22px;
    }

    .logo {
        height: 70px;
        object-fit: contain;
    }

    &.normalSize {
        .text {
            @include open-sans-26-regular;
            padding-right: 22px;
        }
        .logo {
            height: 70px;
        }

        &.animated {
            .text {
                @include open-sans-26-regular;
                padding-right: 0px;
            }
            .logo {
                height: 115px;
                transform: translateX(-20px);
            }
        }
    }

    &.smallSize {
        .text {
            @include open-sans-14-regular;
            padding-right: 8px;
        }
        .logo {
            height: 30px;
        }

        &.animated {
            .text {
                @include open-sans-14-regular;
                padding-right: 0px;
            }
            .logo {
                height: 50px;
                transform: translateX(-12px);
            }
        }
    }

    &.dark {
        .text {
            color: $whiteColor;
        }
    }

    &.light {
        .text {
            color: $mainColor;
        }
    }
}
