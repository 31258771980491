@import "./../../styles/colors";
@import "./../../styles/mixins";
@import "./../../styles/animations";

.container {
    width: 100%;
    position: relative;
    overflow: hidden;

    .scrollContainer {
        width: 100%;
        overflow: auto;
        padding-top: 32px;
        padding-bottom: 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .containerElement {
        padding: var(--blueprint-cell-margin) 0;
        background: $orangeColor;
        border-radius: 7px;
        width: var(--blueprint-container-width);
        margin: auto;

        animation: fadeIn 0.2s ease-out forwards;

        .title {
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            & + .block {
                margin-top: var(--blueprint-cell-margin);
            }
        }

        .block {
            position: relative;
            margin: 0 var(--blueprint-cell-margin);

            &.selected {
                .cell {
                    border-width: 3px;
                }
            }

            .cell {
                width: var(--blueprint-cell-width);
                height: var(--blueprint-cell-height);
                background: $greyColor;
                border: 1px solid $mainColor;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 7px;
                position: relative;
                overflow: hidden;

                .cellName {
                    text-align: center;
                    max-width: 100%;
                    max-height: 100%;
                    white-space: pre-line;
                    line-height: 16px;
                    word-break: break-word;
                    padding: 0 3px;
                }
            }

            .outerConnection {
                $arrowHeight: 12px;
                position: absolute;
                width: calc(var(--blueprint-cell-margin) * 2);
                top: calc(var(--blueprint-cell-center-y) - $arrowHeight/2);
                height: $arrowHeight;
                object-fit: cover;

                &.incoming {
                    right: 100%;
                }
                &.outgoing {
                    left: 100%;
                }
            }
        }

        .innerConnection {
            display: block;
            margin: 0 auto;
            height: var(--blueprint-cell-inner-arrow-height);
        }

        .cell,
        .innerConnection,
        .outerConnection {
            cursor: pointer;
            transition: all 0.2s ease;

            &:active {
                opacity: 0.4;
            }
        }
    }

    .options {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        @include modal-overlay;
        .overlay {
            position: absolute;
            z-index: 0;
        }

        .scroller {
            height: 100%;
            position: relative;
            overflow: auto;

            .optionsContainer {
                position: relative;
                margin-top: 24px;
                margin-bottom: 24px;
                opacity: 0;

                animation: fadeIn 0.2s 0.2s ease-out forwards;

                .label {
                    color: $whiteColor;
                    margin-bottom: 20px;
                    margin-left: 24px;
                }

                .close {
                    position: absolute;
                    top: -12px;
                    right: 12px;
                    height: 32px;
                    width: 32px;
                }

                .containerElement {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                    cursor: pointer;
                    transition: all 0.2s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    &:active {
                        transform: scale(0.8);
                    }

                    & + .containerElement {
                        margin-top: 32px;
                    }
                }
            }
        }
    }
}

.storybook {
    width: 350px;
    height: 800px;
    border: 2px solid $mainColor;
}
