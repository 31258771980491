@import "colors";
@import "toastify";

html {
    box-sizing: border-box;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Open Sans", sans-serif;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
        height: 100%;
    }
}

a {
    text-decoration: none;
}
