@import "./../../styles/colors";

$blockWidth: 300px;
$blockHorizontalMargin: 16px;
$blockTotalSize: $blockWidth + 2 * $blockHorizontalMargin;
$maxBlocksPerRow: 10;

.container {
    margin: 24px;

    .title {
        margin-bottom: 32px;
    }

    .nav {
        display: block;
        + .nav {
            margin-top: 24px;
        }
    }
}
