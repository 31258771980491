@import "./../../styles/colors";

.container {
    background: $whiteColor;
    width: 100%;
    height: 48px;
    display: grid;
    grid-template-columns: 220px 140px auto 140px 220px;
    grid-gap: 24px;
    justify-items: center;
    align-items: center;
    box-shadow: 0px 2px 6px #0000000f;

    .partnerLogo {
        place-self: center start;
        background-position: center left;
        margin-left: 28px;
        &:hover {
            cursor: pointer;
        }
    }

    .controls {
        display: flex;
        > * + * {
            margin-left: 6px;
        }
    }

    .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .profileInfo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        place-self: center start;
        padding-left: 12px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;

        &.active,
        &:hover {
            background: $greyColor;
        }

        .profileAvatar {
            flex-shrink: 0;
            margin-right: 14px;
        }

        .profileName {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 150px;
        }

        .dropdown {
            position: absolute;
            width: 200px;
            top: 100%;
            right: 0;
            transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
            opacity: 0;
            transform: scale(0.8, 0.8) translate(0);
            transform-origin: top right;
            z-index: 1000;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            animation: none;

            &.enterActive {
                opacity: 0;
            }

            &.enterDone {
                opacity: 1;
                transform: scale(1) translate(-10px, 9px);
                .dropdownItem {
                    opacity: 1;
                }
            }
            &.exitActive {
                opacity: 0;
                transform: scale(0.6, 0.7) translate(0, 4px);
            }
            &.exitDone {
                display: none;
            }

            .dropdownItem {
                padding: 12px;
                transition: background 0.2s ease-out, opacity 0.1s ease;
                cursor: pointer;
                opacity: 0;

                &:hover {
                    background: $greyColor;
                }
            }
        }
    }
}
