@import "./../../styles/colors";
@import "./../../styles/mixins";

.container {
    min-width: 400px;
    width: 80%;
    background: $whiteColor;
    min-height: 600px;
    height: 80%;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 0px 20px #00000029;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .header {
        @include modal-header-content;
        flex-shrink: 0;
    }

    .contentContainer {
        flex: 1;
        width: 100%;
        padding: 28px;
        overflow: auto;

        .titleContainer {
            display: flex;
            flex-direction: row;
            align-content: center;

            $height: 36px;

            .title {
                line-height: $height;
            }
            .icon {
                height: $height;
                margin-left: 12px;
            }
        }
    }
}

@include modal-overlay;
