@import "./../../styles/colors";

.container {
    padding: 24px;

    .title {
        margin-top: 32px;
        margin-bottom: 24px;
    }
}
