@import "./../../styles/colors";
@import "./../../styles/dimensions";
@import "./../../styles/text";

.container {
    position: relative;

    .containerInput {
        background: transparent;
        border: none;
        color: $mainColor;
        text-align: center;
        vertical-align: middle;
        @include open-sans-16-bold;
        line-height: 16px;
    }

    .externalSystemInput,
    .cellInput {
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        resize: none;
        @include open-sans-16-regular;
        color: $mainColor;
        text-align: center;
        vertical-align: middle;
        line-height: 16px;
        word-wrap: "break-word";
        padding: 0 3px;
    }

    .miniMap {
        pointer-events: none;
        position: absolute;
        right: 20px;
        top: 20px;
        opacity: 0.9;
        border: 1px solid $separatorColor;
        background: $greyColor;
        z-index: 10;
        transition: all 0.3s 0.2s linear;

        &.hide {
            opacity: 0;
        }
    }
}

.storybook {
    height: $maxBlueprintCanvasHeight;
    width: $maxBlueprintCanvasWidth;
    overflow: hidden;
    border: 2px solid $mainColor;
}
