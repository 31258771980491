@import "./../../styles/colors";
@import "./../../styles/mixins";

.container {
    width: 460px;
    background: $whiteColor;
    min-height: 200px;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 0px 20px #00000029;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .header {
        @include modal-header-content;
        flex-shrink: 0;

        .title {
            padding-left: 16px;
            flex: 1;
        }
    }

    .contentContainer {
        border-radius: 7px;
        padding: 20px;
        margin: 20px;
        border: 1px solid $separatorColor;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .sectionHeader {
            margin-bottom: 8px;
        }

        .option {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 20px;
            margin-bottom: 12px;

            .optionName {
                flex: 1;
                text-transform: capitalize;
            }

            .toggle {
                flex-shrink: 0;
            }

            & + .sectionHeader {
                margin-top: 12px;
            }
        }
    }
}

@include modal-overlay;
