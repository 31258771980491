@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s ease-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $iconSize;
    height: $iconSize;

    &.rounded {
        border-radius: 7px;
    }

    &.active {
        background-color: $separatorColor;
    }

    &:hover {
        background-color: $greyColor;
    }

    &:active {
        opacity: 0.5;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    .image {
        max-height: calc($iconSize * $reduction);
        max-width: calc($iconSize * $reduction);
        transform: scale($reduction);
    }

    &.dark {
        background-color: $mainColor;
    }

    &.hide {
        pointer-events: none;
        opacity: 0;
        transform: scale(0.8);
    }
}
