@mixin open-sans-font {
    font-family: "Open Sans", sans-serif;
}

@mixin patrick-hand-font {
    font-family: "Patrick Hand", "Comic Sans MS", "Comic Sans", cursive;
}

@mixin common-style {
    letter-spacing: 0;
}

@mixin open-sans-14-regular {
    @include open-sans-font;
    @include common-style;

    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
}

@mixin open-sans-28-semibold {
    @include open-sans-font;
    @include common-style;

    font-size: 21px;
    line-height: 28px;
    font-weight: 600;
}

@mixin open-sans-18-semibold {
    @include open-sans-font;
    @include common-style;

    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}

@mixin open-sans-18-bold-italic {
    @include open-sans-font;
    @include common-style;

    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    font-style: italic;
}

@mixin open-sans-16-regular {
    @include open-sans-font;
    @include common-style;

    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
}

@mixin open-sans-20-semibold {
    @include open-sans-font;
    @include common-style;

    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
}

@mixin patrick-hand-18-regular {
    @include patrick-hand-font;
    @include common-style;

    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

@mixin open-sans-16-semibold {
    @include open-sans-font;
    @include common-style;

    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}

@mixin open-sans-16-bold {
    @include open-sans-font;
    @include common-style;

    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
}

@mixin open-sans-22-regular {
    @include open-sans-font;
    @include common-style;

    font-size: 19px;
    line-height: 24px;
    font-weight: normal;
}

@mixin open-sans-26-regular {
    @include open-sans-font;
    @include common-style;

    font-size: 21px;
    line-height: 30px;
    font-weight: normal;
}

@mixin patrick-hand-39-regular {
    @include patrick-hand-font;
    @include common-style;

    font-size: 26px;
    line-height: 30px;
    font-weight: normal;
}
