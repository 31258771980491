@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $separatorColor;
        height: $barHeight;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        .description {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon {
                flex-shrink: 0;
            }
            .label {
                flex: 1;
            }
        }
        .button {
            flex-shrink: 0;
        }
    }

    .blueprint {
        flex: 1;
    }
}
