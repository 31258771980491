@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    @include open-sans-16-regular;
    color: $mainColor;
    text-decoration: underline;

    transition: all 0.2s ease-out;
}
