@import "./../../styles/colors";

.container {
    max-width: 520px;

    .group {
        margin-top: 16px;
        border-top: 2px solid $separatorColor;
        margin-bottom: 16px;

        .organisation {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 12px 0;

            .logo {
                margin-left: 12px;
                max-width: 80px;
            }

            .organisationName {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .contextSection {
            .subContextSection {
                margin-left: 24px;
                border-left: 2px solid $separatorColor;

                .subContext {
                    padding-left: 12px;
                    padding-top: 18px;
                }
            }

            & + .contextSection {
                margin-top: 24px;
            }
        }
    }
}
