@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    @include open-sans-28-semibold;
    color: $mainColor;

    &.h1 {
        @include open-sans-28-semibold;
    }
    &.h2 {
        @include open-sans-20-semibold;
    }
}
