@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    .text {
        margin-bottom: 10px;
    }

    .input {
        @include open-sans-16-regular;
        width: 100%;
        color: $mainColor;
        cursor: text;
        border-radius: 7px;
        background-color: $greyColor;
        border: none;
        padding: 11px 16px;
        transition: all 0.2s ease-out;

        &::placeholder {
            opacity: 0.5;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }
}
