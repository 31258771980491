@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    height: calc($barHeight * 2 + 1px);
    display: flex;
    flex-direction: row;
    overflow: initial;
    width: 100%;

    .timeline {
        flex: 1;
    }

    .actions {
        flex-shrink: 0;
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 1px;
        padding-left: 1px;

        background: $separatorColor;

        > * {
            background: $whiteColor;
        }

        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        overflow: hidden;

        .fill {
            grid-column: 2;
            grid-row: 1 / 3;

            .button {
                height: 100%;
            }
        }
    }
}
