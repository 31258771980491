@import "./../../styles/colors";

.container {
    .info {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        margin-bottom: 8px;

        .avatar,
        .favorite,
        .timestamp {
            flex-shrink: 0;
        }

        .avatar {
            // margin-left is to center with the speech bubble triangle.
            margin-left: 5px;
            margin-right: 10px;
        }

        .name {
            flex: 1;
        }

        .timestamp {
            margin-left: 4px;
        }
    }

    .bubble {
        padding: 12px 18px;
        position: relative;
        overflow: initial;
        white-space: pre-line;

        // @NOTE(Lejun) might just replace this triangle with an asset, border is too thin when $borderSize 1px and too thick with 2px.
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 0;
        }

        $cornerSize: 10px;
        $cornerOffset: 10px;
        $borderSize: 2px;
        &:before {
            left: $cornerOffset - $borderSize;
            bottom: 100%;
            border: ($cornerSize + $borderSize) solid transparent;
            border-bottom-color: $separatorColor;
            border-top: 0;
        }

        &:after {
            left: $cornerOffset;
            bottom: 100%;
            border: $cornerSize solid transparent;
            border-bottom-color: $whiteColor;
        }
    }
}
