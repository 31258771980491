@import "./../../styles/colors";

.container {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr minmax(200px, 2fr) auto auto;
    gap: 12px;

    .input {
        + .button {
            margin-left: 12px;
        }
    }

    .avatarContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .avatarInput {
            flex: 1;
        }

        .avatar {
            flex-shrink: 0;
            margin-left: 4px;
        }
    }

    .passwordContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .passwordInput {
            flex: 1;
        }

        .passwordGenerator {
            flex-shrink: 0;
        }
    }

    .dropdown {
        width: 100%;
    }
}
