@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    .topLine {
        width: 100%;
        height: 6px;
        transition: all 0.3s ease;

        &.aubergine {
            background: $aubergineColor;
        }
        &.purple {
            background: $purpleColor;
        }
        &.orange {
            background: $orangeColor;
        }
        &.green {
            background: $greenColor;
        }
        &.red {
            background: $redColor;
        }
        &.blue {
            background: $blueColor;
        }
        &.main {
            background: $mainColor;
        }
    }

    .headerContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid $separatorColor;
        height: $barHeight;
        justify-content: center;
        align-items: center;

        .content {
            flex: 1;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        .headerItem {
            flex-shrink: 0;
        }
    }
}
