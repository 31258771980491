@import "./../../styles/colors";
@import "./../../styles/mixins";

.container {
    min-width: 460px;
    width: 50%;
    background: $whiteColor;
    min-height: 200px;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 0px 20px #00000029;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .header {
        @include modal-header-content;
        flex-shrink: 0;

        .title {
            padding-left: 16px;
        }
    }

    .contentContainer {
        flex: 1;
        width: 100%;
        padding: 36px;
        display: flex;
        flex-direction: column;
        min-height: 200px;
        justify-content: space-between;

        .message {
            text-align: center;
            margin-bottom: 40px;
        }

        .buttonContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .button {
                min-width: 200px;

                & + .button {
                    margin-left: 34px;
                }
            }
        }
    }
}

@include modal-overlay;
