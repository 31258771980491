@import "./../../styles/colors";
@import "./../../styles/dimensions";

.storybook {
    height: $maxMemoCanvasHeight;
    width: $maxMemoCanvasWidth;
    overflow: hidden;
    border: 2px solid $mainColor;
}

.container {
    transition: all 0.4s 0.15s ease-out;
}

.hovering {
    transition: all 0.2s ease-in;
    &.tables {
        background: $mutedAubergineColor80;
    }
    &.computations {
        background: $mutedOrangeColor80;
    }
    &.interfaces {
        background: $mutedRedColor80;
    }
    &.validations {
        background: $mutedGreenColor80;
    }
    &.mutations {
        background: $mutedPurpleColor80;
    }
    &.processes {
        background: $mutedBlueColor80;
    }
}
