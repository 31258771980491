$mainColor: #061133;
$accentColor: #3c6e71;
$secondaryAccentColor: #28b6eb;
$whiteColor: #ffffff;
$greyColor: #f5f5f5;
$highlightColor: #284b63;

$separatorColor: #dbdde2;
$selectedContextColor: #e1e4ed;
$dropdownOptionsColor: #eaeaea;

$aubergineColor: var(--memo-color-aubergine);
$mutedAubergineColor: var(--memo-color-aubergine-muted);
$mutedAubergineColor80: #d5cad680;
$purpleColor: var(--memo-color-purple);
$mutedPurpleColor: var(--memo-color-purple-muted);
$mutedPurpleColor80: #ebd0df80;
$orangeColor: var(--memo-color-orange);
$mutedOrangeColor: var(--memo-color-orange-muted);
$mutedOrangeColor80: #fde0c780;
$greenColor: var(--memo-color-green);
$mutedGreenColor: var(--memo-color-green-muted);
$mutedGreenColor80: #e0edcf80;
$redColor: var(--memo-color-red);
$mutedRedColor: var(--memo-color-red-muted);
$mutedRedColor80: #f1c5c880;
$blueColor: var(--memo-color-blue);
$mutedBlueColor: var(--memo-color-blue-muted);
$mutedBlueColor80: #c8ecf980;

$toggleSwitchOffColor: #dbdde1;
$toggleSwitchOnColor: $greenColor;
