@import "./../../styles/colors";
@import "./../../styles/mixins";
@import "./../../styles/animations";
@import "./../../styles/dimensions";

$margin: 20px;

.container {
    min-width: 700px;
    width: 90%;
    max-width: 1200px;
    background: $whiteColor;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0px 0px 20px #00000029;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: $barHeight;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid $separatorColor;

        .title {
            margin-right: 20px;
        }
        .details {
            margin: 0 20px;
        }
    }

    .contentContainer {
        flex: 1;
        width: 100%;
        padding: $margin;

        .steps {
            border-radius: 7px;
            border: 1px solid $separatorColor;
            padding: $margin;

            .step {
                display: flex;
                flex-direction: row;
                align-items: center;

                & + .step {
                    margin-top: 24px;
                }

                .image {
                    box-shadow: 0px 2px 2px #06113327;
                    border-radius: 4px;
                    border: 1px solid $mainColor;
                    overflow: hidden;
                }

                .name {
                    margin-left: 22px;
                    width: 235px;
                }

                .progressContainer {
                    flex: 1;
                    overflow: hidden;
                    background: $greyColor;
                    height: 15px;
                    border-radius: 15px;

                    .bar {
                        height: 100%;
                        background: $greenColor;
                        width: 100%;
                        border-radius: 15px;
                        transform: translateX(-100%);
                        animation: slideInLeft forwards ease;
                    }
                }

                .checkbox {
                    width: 30px;
                    height: 30px;
                    margin-left: $margin;
                    background: $greyColor;
                    overflow: hidden;
                    border-radius: 7px;

                    .tick {
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        animation: fadeIn 0.3s forwards ease-in;
                    }
                }
            }
        }

        .confirm {
            padding-left: 40px;
            padding-right: 40px;
            display: block;
            margin-left: auto;
            margin-top: $margin;
        }
    }
}

@include modal-overlay;
