@import "./../../styles/colors";

.container {
    background-color: $greyColor;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.normal {
        height: 36px;
        width: 36px;
    }

    &.small {
        height: 32px;
        width: 32px;
    }
}
