@import "./../../styles/colors";
@import "./../../styles/dimensions";

.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $iconSize;
    height: $iconSize;
    background-color: transparent;

    .image {
        max-height: calc($iconSize * $reduction);
        max-width: calc($iconSize * $reduction);
        transform: scale($reduction);
    }
}
