@import "./../../styles/colors";
@import "./../../styles/text";

.container {
    @include open-sans-16-semibold;
    text-align: center;
    padding: 11px;
    border-radius: 7px;
    border: none;
    transition: all 0.2s ease-out;
    cursor: pointer;

    &.default {
        color: $whiteColor;
        background-color: $secondaryAccentColor;
    }

    &.alternative {
        color: $whiteColor;
        background-color: $mainColor;
    }

    &.hollow {
        color: $mainColor;
        background-color: transparent;
        border: 1px solid $mainColor;
        // line-height 2px smaller than the actual text style to accommodate border 2*1px (top and bottom)
        line-height: 16px;
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.5;
    }

    &:disabled {
        background-color: $greyColor;
        cursor: not-allowed;
    }
}
